<template>
  <div class="location-home-mobile" v-if="location==='home'">

    <search-mobile-up />

    <div class="btn-group directcheck">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="checkbox" value="">
        <label class="form-check-label" for="checkbox">{{$t("sabre.search-panel.direct-flights-only")}}</label>
      </div>
    </div>

    <search-mobile-down />

    <!-- must check css style for this button -->
    <button href="#" class="btn btn-outline-primary" :class="{'disabled': (!isSearchAvailable || isLoading)}" @click="startSearch">{{$t("sabre.buttons.update-search")}}</button>
    <!-- must check css style for this button -->

  </div>
  <div class="directflightsonly_area desktopmobail" v-else>
    <div class="container">

      <div class="directflightsonly_innerbox">

        <search-mobile-up />

        <div class="btn-group directcheck">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="checkbox" value="">
            <label class="form-check-label" for="checkbox">{{$t("sabre.search-panel.direct-flights-only")}}</label>
          </div>
        </div>

        <search-mobile-down />

        <!-- must check css style for this button -->
        <button href="#" class="btn btn-outline-primary" :class="{'disabled': (!isSearchAvailable || isLoading)}" @click="startSearch">{{$t("sabre.buttons.update-search")}}</button>
        <!-- must check css style for this button -->

      </div>

      <div class="directflightsonly_innerbox_two"
        :class="device==='mobile' && isScroll ? 'fix': 'd-none'">
        <div class="directflightsonly_twomobail">
          <h3>
            תל אביב - ברצלונה
          </h3>
          <p><small>25/1/2023 - 2/2/2023</small><small> 2 מבוגרים</small></p>
          <div class="btnedit_area d-flex align-items-center ml-4">
            <button class="editbtn" @click="showSearchFilterPanel('search')"><i class="fa-solid fa-pen"></i></button>
            <button class="editbtn fixbtn" @click="showSearchFilterPanel('filter')"><i class="fa-solid fa-sliders"></i><span>{{filterAppliedCount}}</span></button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import startSearch from '../startSearchMixin';
import filterCount from '../filterCountMixin';

export default {
  name: 'sabre-search-mobile-pane',
  mixins: [startSearch, filterCount],
  props: {
    location: {
      type: String,
      default: '',
    },
  },
  components: {
    searchMobileUp: () => import('./searchMobileUp'),
    searchMobileDown: () => import('./searchMobileDown'),
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      isLoading: 'GET_LOADING_STATE',
    }),
  },
  data() {
    return {
      isScroll: false,
    };
  },
  mounted() {
    document.querySelector('.st-content').addEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll(event) {
      this.isScroll = Number(event.target.scrollTop) > 350;
    },
    showSearchFilterPanel(type) {
      if (type === 'search') {
        this.$root.$emit('goto-top-to-show-search-panel', true);
      }
      if (type === 'filter') {
        this.$root.$emit('show-mobile-filter-modal', true);
      }
    },
  },
};

</script>
<style>
@media (max-width: 991px){
  .location-home-mobile .btn-group {
      margin-left: 0;
      margin-top: 10px;
      display: flex;
  }
}
.location-home-mobile .form-check .form-check-input {
    float: right;
    margin-right: -1.5em;
}
.location-home-mobile .form-check {
    padding-right: 1.5em;
}
</style>
